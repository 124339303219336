import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";

import constant from "../../constant";
function DiscountBanner() {
  const imageBaseURL = constant.BASE_URL_UPLOADS;

  const [toaster, setToaster] = useState({ visiblity: "hide" });

  const [DiscountBanner, setDiscountBanner] = useState("");
  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState();
  const OnSubmithandle = async (a) => {
    let isload = true;
    let data;
    a == "status"
      ? (data = {
          name: "DISCOUNT_BANNER",
          status: status,
        })
      : (data = {
          name: "DISCOUNT_BANNER",
          html: DiscountBanner,
          status: status,
        });

    if (isload) {
      try {
        const response = await services.CMS.UPDATE_CMS(data);
        if (response) {
          if (a == "Data") {
            setToaster({
              type: "success",
              title: "Successful",
              text: "Banner updated successfully",
              visiblity: "show",
            });
            setTimeout(() => {
              setToaster({
                visiblity: "hide",
              });
            }, 1000);
          }
        }
      } catch (error) {
        if (a == "Data") {
          setToaster({
            type: "danger",
            title: "Error Occured",
            text: error.response.data.message,
            visiblity: "show",
          });
          setTimeout(() => {
            setToaster({
              visiblity: "hide",
            });
          }, 1000);
        }
      }
    } else {
    }
  };

  const uploadFabricImage = async () => {
    if (DiscountBanner) {
      const formData = new FormData();
      formData.append("image", DiscountBanner);

      try {
        const response = await services.subCategory.UPLOAD_IMAGE(formData);
        if (response) {
          setDiscountBanner(response?.data?.pic);

          setToggle(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };
  const handleImageUploadFabric = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDiscountBanner(file);
      setToggle(true);
    } else {
    }
  };
  const GET_CMS = async () => {
    try {
      const response = await services.CMS.GET_CMS();
      setDiscountBanner(response?.data?.data?.rows[0]?.html);
      setStatus(response?.data?.data?.rows[0]?.status);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    GET_CMS();
  }, []);
  useEffect(() => {
    OnSubmithandle("status");
  }, [status]);
  const handleToggle = (value) => {
    setStatus(value);
  };
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Banner</h4>
                </div>

                <div className="row ">

                  <div className="col-md-12">
                    
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                    <div className="mt-3 ms-4 d-flex "
                    
                    >
                         <div className="mt-3  d-flex justify-content-start" style={{ marginTop: "48px"}}>
                         <div className="">Status</div>
                            <div className="ms-2 form-check form-switch">
                              <input
                                className="form-check-input  text-end"
                                type="checkbox"
                                checked={status}
                                onChange={(e) => {
                                  handleToggle(e.target.checked);
                                }}
                              />
                            </div>
                         </div>
                            </div>
                      
                      <div
                        className="card-body"
                        style={{ margin: "16px", padding: "10px" }}
                      >
                        
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-30px" }}
                        >
                          <div className="col-md-4 mt-4">
                      
                            {DiscountBanner && toggle ? (
                              <>
                                <img
                                  src={URL.createObjectURL(DiscountBanner)}
                                  alt="FabricIamge"
                                  className="uploaded-image mb-5"
                                  height={100}
                                  width={100}
                                  style={{ borderRadius: "5px" }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={imageBaseURL + DiscountBanner}
                                  alt={`FabricImage `}
                                  className="uploaded-image mb-1"
                                  height={100}
                                  width={100}
                                  crossOrigin="anonymous"
                                  style={{
                                    borderRadius: "5px",
                                    marginRight: "12px",
                                  }}
                                />
                              </>
                            )}
                            <br />
                            {DiscountBanner && toggle ? (
                              <>
                                {" "}
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={uploadFabricImage}
                                  style={{
                                    position: "absolute",
                                    marginTop: "-35px",
                                    marginLeft: "7px",
                                    paddingLeft: "25px",
                                    paddingRight: "25px",
                                  }}
                                >
                                  Upload
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                            {/* <div className="form-check form-switch">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                checked={status}
                                onChange={(e) => {
                                  handleToggle(e.target.checked);
                                }}
                              />
                            </div> */}
                            &nbsp;
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Banner
                            </label>
                            <div>
                              <input
                                type="file"
                                accept="image/*"
                                className="form-control "
                                placeholder="Enter Value"
                                aria-describedby="defaultFormControlHelp"
                                onChange={handleImageUploadFabric}
                              />
                            </div>
                          </div>

                          <div className="col-md-4 mt-4 ">
                            <button
                              type="submit"
                              onClick={() => OnSubmithandle("Data")}
                              className="btn btn-primary ml-md-10"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountBanner;
