import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";
import constant from "../../constant"
function EditSubadmin() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [firstName, setfirstname] = useState('')
 const [lastName, setlastName] = useState('')
 const [email, setemail] = useState('')
 const [phoneNumber, setphoneNumber] = useState('')
  


  const OnSubmithandle = async () => {
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      role: 'subadmin',
      countryCode: '+91'
      // data: carData
    };
    try {
      const response = await services.Users.UPDATE_USERS(id, data);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Add Category successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          navigate("/subadmins");
        }, 500);
      }
    } catch (error) {
     
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error.response.data.message,
        visiblity: "show",
      });
      setTimeout(() => {
        setToaster({
          visiblity: "hide",
        });
      }, 1500);
    }

  };
const getUserDetails = async(id) =>{
  try {
    const response  = await services.Users.GET_USERS_BY_ID(id);
    setfirstname(response.data.firstName)
    setlastName(response.data.lastName)
    setphoneNumber(response.data.phoneNumber)
    setemail(response.data.email)
  } catch (error) {
    console.log(error)
  }
}
useEffect(()=>{
  console.log(id)
  if(id){
    getUserDetails(id)
  }
},[])
return (
  <div className="App">
    <Toaster
      type={toaster.type}
      title={toaster.title}
      text={toaster.text}
      visiblity={toaster.visiblity}
    />
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />

        <div className="layout-page">
          <Header />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="d-flex py-3 align-items-center justify-content-between">
                <h4 className="fw-bold ">Add Sub Admin</h4>
              </div>

              <div className="row ">
                <div className="col-md-12">
                  <div className="card mb-4" style={{ margin: "-12px" }}>
                    <div
                      className="card-body"
                      style={{ margin: "5px", padding: "10px" }}
                    >
                      <div
                        className="row align-items-end"
                        style={{ marginTop: "-35px" }}
                      >
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="form-label"
                          >
                            First Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="defaultFormControlInput"
                            placeholder="Enter Value"
                            aria-describedby="defaultFormControlHelp"
                            value={firstName}
                            onChange={(e) => {
                              setfirstname(e.target.value);
                            }}
                          />
                          
                        </div>
                        
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="form-label"
                          >
                            Last Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="defaultFormControlInput"
                            placeholder="Enter Value"
                            aria-describedby="defaultFormControlHelp"
                            value={lastName}
                            onChange={(e) => {
                              setlastName(e.target.value);
                            }}
                          />
                          
                        </div>
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="form-label"
                          >
                            Phonenumber{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="defaultFormControlInput"
                            placeholder="Enter Value"
                            aria-describedby="defaultFormControlHelp"
                            value={phoneNumber}
                            onChange={(e) => {
                              setphoneNumber(e.target.value);
                            }}
                          />
                          
                        </div>
                        
                        <div className="col-md-6 mt-4">
                          <label
                            htmlFor="defaultFormControlInput"
                            className="form-label"
                          >
                            Email{" "}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="defaultFormControlInput"
                            placeholder="Enter Value"
                            aria-describedby="defaultFormControlHelp"
                            value={email}
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                          />
                          
                        </div>
                        <div className="col-md-4 mt-4 ">
                          <button
                            type="submit"
                            disabled={
                              !(
                                firstName &&
                                lastName &&
                                email &&
                                phoneNumber
                              )
                            }
                            onClick={() => {
                              OnSubmithandle();
                            }}
                            className="btn btn-primary ml-md-10"
                          >
                            Save Data
                          </button>
                        </div>
                        
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="content-footer footer bg-footer-theme">
              <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">© Cars53</div>
              </div>
            </footer>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  </div>
);  
}

export default EditSubadmin;
