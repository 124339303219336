import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./views/Login/login";

import Axios from "axios";

import UsersList from "./views/User/usersList";

import OlrderList from "./views/Order/order";
import EditOrder from "./views/Order/editOrder";
import OrderDetails from "./views/Order/orderDetails";
import NotFound from "./views/notFound/notFound";
import initialiseInterceptor from "./api/interceptor";
import PaymentLog from "./views/paymentLog/paymentlog";
import AddressDetails from "./views/User/addresDetails";
import Coupon from "./views/Coupon/Coupon";
import Addcoupon from "./views/Coupon/Addcoupon";
import CMS from "./views/CMS/CMS";
import Contact from "./views/Contact/Contact";
import DiscountBanner from "./views/Discount/DiscountBanner";
import AddMasterCar from "./views/MasterCars/addMastercars";
import EditMasterCars from "./views/MasterCars/editMastercars";
import MasterCarsList from "./views/MasterCars/mastercars";
import VendorsList from "./views/Vendors/vendors";
import AddVendor from "./views/Vendors/addvendor";
import EditVendor from "./views/Vendors/editvendor";
import Drivers from "./views/Driver";
import EditDriver from "./views/Driver/editDriver";
import VendorsCarList from "./views/Vendors/vendorcar";
import SystemConfig from "./views/SystemConfig";
import Dashboard from "./views/Dashboard";
import SubAdminList from "./views/Subadmins/subadmins";
import AddSubadmin from "./views/Subadmins/addsubadmins";
import EditSubadmin from "./views/Subadmins/editsubadmins";

Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

function App() {
  initialiseInterceptor();

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Login />} />
    
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="vendors" element={<VendorsList />} />
          <Route path="addvendor" element={<AddVendor />} />
          <Route path="editvendor/:id" element={<EditVendor />} />
          <Route path="vendorcar/:id" element={<VendorsCarList />} />
          <Route path="users" element={<UsersList />} />
          <Route path="addressDetails/:id" element={<AddressDetails />} />
          <Route path="orders" element={<OlrderList />} />
          <Route path="system-config" element={<SystemConfig />} />
          <Route path="orderdetails/:id" element={<OrderDetails />} />

          <Route path="editorder/:id" element={<EditOrder />} />
          <Route path="master-cars" element={<MasterCarsList />} />
          <Route path="add-master-cars" element={<AddMasterCar />} />
          <Route path="edit-master-cars/:id" element={<EditMasterCars />} />
          <Route path="subadmins" element={<SubAdminList />} />
          <Route path="add-subadmin" element={<AddSubadmin />} />
          <Route path="edit-subadmin/:id" element={<EditSubadmin />} />
         
          <Route path="drivers" element={<Drivers />} />
          <Route path="editdriver/:id" element={<EditDriver />} />
          <Route path="payment" element={<PaymentLog />} />
          <Route path="coupon" element={<Coupon />} />
          <Route path="addcoupon" element={<Addcoupon />} />
          <Route path="CMS" element={<CMS />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Discountbanner" element={<DiscountBanner />} />

        
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
