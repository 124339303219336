

function Header() {
  function openSideBar() {

    document.querySelector('#layout-menu').classList.add("expand-sidebar")
    document.getElementById('layout-menu-toggle').classList.add("d-none")
    document.getElementById('layout-menu-toggle').classList.add("d-none")
    document.getElementById('layout-menu-close').classList.remove("d-none")
  }
  return (
    <nav
      className=" container-xxl navbar navbar-expand-xl  align-items-center "
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none cursor-pointer" id="layout-menu-toggle" onClick={() => openSideBar()}>
        {/* <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)"> */}
        <i className="bx bx-menu bx-sm"></i>
        {/* </a> */}
      </div>
      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        {/* <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <i className="bx bx-search fs-4 lh-0"></i>
            <input
              type="text"
              className="form-control border-0 shadow-none"
              placeholder="Search..."
              aria-label="Search..."
            />
          </div>
        </div> */}
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
              <div className="avatar avatar-online">
                <img src="https://www.proteomics.uni-freiburg.de/images/team/portrait-dummy.png/image" className="w-px-40 h-auto rounded-circle" />
              </div>
            </a> */}

          {/* <ul className="dropdown-menu dropdown-menu-end">
                <li>
            
                <Link className="dropdown-item" to="/product  ">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img src="https://www.proteomics.uni-freiburg.de/images/team/portrait-dummy.png/image" className="w-px-40 h-auto rounded-circle" />
                      </div>
                    </div>

                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">John Doe</span>
                      <small className="text-muted">Admin</small>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link className="dropdown-item" to="/profile">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                      <a className="dropdown-item" href="#">
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">Settings</span>
                      </a>
                    </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <span className="dropdown-item" onClick={() => logOutHandler()}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </span>
              </li>
            </ul> */}
          {/* </li> */}
        </ul>
      </div>
    </nav>
  );
}
export default Header;