import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";
import constant from "../../constant";

function AddVendor() {
  let navigate = useNavigate();
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [aadharLinkedPhoneNumber, setAadharLinkedPhoneNumber] = useState();
  const [alternativePhoneNumber, setAlternativePhoneNumber] = useState();
  const [registerationNumber, setRegisterationNumber] = useState();
  const [aadharNumber, setAadharNumber] = useState();
  const [businessEmail, setBusinessEmail] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessLogo, setBusinessLogo] = useState();
  const [approvalStatus, setApprovalStatus] = useState('approved');
  const [oneWayCommission, setOneWayCommission] = useState();
  const [twoWayCommission, setTwoWayCommission] = useState();
  const [sharingCommission, setSharingCommission] = useState();




  const OnSubmithandle = async () => {
    let isload = true;

    const data = {
      userData: {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        countryCode: '+91',
        email: businessEmail
      },
      vendorData: {
        aadharLinkedPhoneNumber: aadharLinkedPhoneNumber,
        alternativePhoneNumber: alternativePhoneNumber,
        registerationNumber: registerationNumber,
        aadharNumber: aadharNumber,
        approvalStatus: approvalStatus,
        oneWayCommission: oneWayCommission,
        twoWayCommission: twoWayCommission,
        sharingCommission: sharingCommission,
      }

    };
    try {
      const response = await services.Vendor.CREATE_VENDOR(data);
      if (response) {

        setToaster({

          type: "success",
          title: "Successful",
          text: "Vendor Added Successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          navigate("/vendors");
        }, 500);
      }
    } catch (error) {
      console.log(error)
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error.response.data.message,
        visiblity: "show",
      });
      setTimeout(() => {
        setToaster({
          visiblity: "hide",
        });
      }, 1500);
    }

    //navigate student list
  };

  const uploadBusinessImage = async (e) => {
    const formData = new FormData();
    let categoryImage = e.target.files[0];
    formData.append("file", categoryImage);

    try {
      const response = await services.Cars.UPLOAD_IMAGE(formData);
      console.log(response)
      setBusinessLogo(response.data.url)
    } catch (error) {
      console.log(error);
    }

  };
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Add Vendor</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div
                        className="card-body"
                        style={{ margin: "16px", padding: "10px" }}
                      >
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-35px" }}
                        >

                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Vendor's First Name{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Vendor's Last Name{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Phone Number{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              type="number"
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);

                              }}
                            />
                            {(phoneNumber?.length < 10 || phoneNumber?.length > 10) && <small className="text-danger">Please enter valid number</small>}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Email{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={businessEmail}
                              onChange={(e) => {
                                setBusinessEmail(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Aadhar Number{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={aadharNumber}
                              onChange={(e) => {
                                setAadharNumber(e.target.value);

                              }}
                            />
                            {(aadharNumber?.length < 12 || aadharNumber?.length > 12) && <small className="text-danger">Please enter addhar valid number</small>}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Aadhar Linked PhoneNumber{" "}
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Enter Value"
                              value={aadharLinkedPhoneNumber}
                              onChange={(e) => {
                                setAadharLinkedPhoneNumber(e.target.value);

                              }}
                            />
                            {(aadharLinkedPhoneNumber?.length < 10 || aadharLinkedPhoneNumber?.length > 10) && <small className="text-danger">Please enter valid number</small>}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Alternative PhoneNumber{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              type="number"
                              value={alternativePhoneNumber}
                              onChange={(e) => {
                                setAlternativePhoneNumber(e.target.value);

                              }}
                            />
                            {(alternativePhoneNumber?.length < 10 || alternativePhoneNumber?.length > 10) && <small className="text-danger">Please enter valid number</small>}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Firm Registeration Number{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={registerationNumber}
                              onChange={(e) => {
                                setRegisterationNumber(e.target.value);

                              }}
                            />

                          </div>

                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              OneWay Commission{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={oneWayCommission}
                              onChange={(e) => {
                                setOneWayCommission(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              TwoWay Commission{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={twoWayCommission}
                              onChange={(e) => {
                                setTwoWayCommission(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Sharing Commission{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={sharingCommission}
                              onChange={(e) => {
                                setSharingCommission(e.target.value);

                              }}
                            />

                          </div>


                          <div className="col-md-4 mt-4">
                            <button onClick={() => OnSubmithandle()} className="btn btn-primary">Submit</button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default AddVendor;
