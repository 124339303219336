import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";
import moment from "moment";
export default function Coupon() {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();

  const [columnDefs] = useState([
    { field: "couponCode", headerName: "Coupon Code", filter: true },
    { field: "discount", headerName: "Discount", filter: true },
    { field: "description", headerName: "Discription", filter: true },
    {
      headerName: "Created At",
      field: "createdAt",
      filter: true,
      cellRenderer: (params) => {
        const Date = params?.data?.createdAt;

        return <div> {Date ? moment(Date).format("DD MMM YYYY") : "NA"}</div>;
      },
    },
    {
      field: "status",
      filter: false,
      cellRenderer: (params) => {
        console.log(params);
        return (
          <div className="form-check form-switch">
            <input
              className="form-check-input mt-2"
              type="checkbox"
              checked={params.data.status === true}
              onChange={(e) => {
                handleToggle(params.data.id, e.target.checked);
              }}
            />
          </div>
        );
      },
    },
  ]);

  const handleToggle = async (id, isActive) => {
    const data = {
      status: isActive,
    };
    try {
      const response = await services.discount.Update_Discount_Coupon(id, data);
      console.log(response);
      if (response) {
        getCategoryListHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: "260",
    }),
    []
  );

  const getCategoryListHandler = async () => {
    try {
      const response = await services.discount.Get_Discount_Coupon();
      setRowData(response?.data?.data?.rows);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategoryListHandler();
  }, []);
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Coupon List</h4>
                  <Link className="btn btn-primary me-2" to={"/addcoupon"}>
                    Add Coupon
                  </Link>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="multiple"
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
