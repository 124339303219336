import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";
import constant from "../../constant"
function EditMasterCars() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [type, setType] = useState('hatchback');
  const [brand, setBrand] = useState('');
  const [customerCapacity, setCustomerCapacity] = useState('');
  const [name, setName] = useState('');
  const [photos, setPhotos] = useState();
  const [carData, setCarData] = useState('');
  const [perKmCharge, setPerKmCharge] = useState();
  

const getCarDetails = async(id) =>{
  try {
    const response = await services.Cars.GET_CAR_BY_ID(id);
    console.log(response)
    setType(response.data.type)
    setBrand(response.data.brand)
    setName(response.data.name)
    setCustomerCapacity(response.data.customerCapacity)
    setPhotos(response.data.photos)
    setPerKmCharge(response.data.perKmCharge)
  } catch (error) {
    console.log(error)
  }
}
  const OnSubmithandle = async () => {
    const data = {
      type:type,
      brand:brand,
      customerCapacity:customerCapacity,
      name:name,
      photos:photos,
      perKmCharge:perKmCharge,
      // data: carData
    };
    try {
      const response = await services.Cars.UPDATE_CAR(id, data);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Add Category successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          navigate("/master-cars");
        }, 500);
      }
    } catch (error) {
     
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error.response.data.message,
        visiblity: "show",
      });
      setTimeout(() => {
        setToaster({
          visiblity: "hide",
        });
      }, 1500);
    }

  };

  const uploadCarImage = async (e) => {
    const formData = new FormData();
    let categoryImage = e.target.files[0];
    formData.append("file", categoryImage);

    try {
      const response = await services.Cars.UPLOAD_IMAGE(formData);
      console.log(response)
      setPhotos([response.data.url])
    } catch (error) {
      console.log(error);
    }

  };
useEffect(()=>{
  console.log(id)
  if(id){
    getCarDetails(id)
  }
},[])
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Edit Master Car</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div
                        className="card-body"
                        style={{ margin: "5px", padding: "10px" }}
                      >
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-35px" }}
                        >
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Car Name{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                            
                          </div>
                          
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Brand Name{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              value={brand}
                              onChange={(e) => {
                                setBrand(e.target.value);
                              }}
                            />
                            
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Car Type{" "}
                            </label>
                            <select className="form-control" onChange={(e)=> setType(e.target.value)}>
                              <option value={'hatchback'}>Hatchback</option>
                              <option value={'sedan'}>Sedan</option>
                              <option value={'muv'}>MUV</option>
                              <option value={'suv'}>SUV</option>
                              <option value={'minibus'}>Minibus</option>
                            </select>
                            
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Customer Capacity{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              value={customerCapacity}
                              onChange={(e) => {
                                setCustomerCapacity(e.target.value);
                              }}
                            />
                            
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Per KM Charge{" "}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              value={perKmCharge}
                              onChange={(e) => {
                                setPerKmCharge(e.target.value);
                              }}
                            />
                            
                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Car Photo{" "}
                            </label>
                            <input
                              type="file"
                              className="form-control"
                            
                              onChange={(e) => {
                                uploadCarImage(e);
                              }}
                            />
                            
                          </div>
                          <div className="col-md-4 mt-4 ">
                            <button
                              type="submit"
                              disabled={
                                !(
                                  name &&
                                  type &&
                                  brand &&
                                  customerCapacity
                                )
                              }
                              onClick={() => {
                                OnSubmithandle();
                              }}
                              className="btn btn-primary ml-md-10"
                            >
                              Save Data
                            </button>
                          </div>
                          {photos && photos.length && photos[0] && <div className="col-md-4 mt-4 ">
                            <img width={'300px'} crossorigin="anonymous" src={constant.IMAGE_URL+photos[0]}/>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );  
}

export default EditMasterCars;
