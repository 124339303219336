import React from "react";

import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import moment from "moment/moment";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";

export default function PaymentLog() {
  const [bookingList, setBookingList] = useState([])
  useEffect(() => {
    getBookingsHandler();
  }, [])
  const getBookingsHandler = async () => {
    try {
      const response = await services.order.GET_BOOKING();

      let data = response.data;
      if (data.length) {
        data.map((item) => {
          item.BookingDetails.sort(function (a, b) {
            return parseFloat(a.id) - parseFloat(b.id);
          });
        })
        var newArray = data.filter(function (el) {
          return el.BookingDetails[0].bookingStatus == 'confirmed' || el.BookingDetails[0].bookingStatus == 'started' || el.BookingDetails[0].bookingStatus == 'ended';
        });
        console.log(newArray)
        setBookingList(newArray)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y card">
                <div className="d-flex py-3 align-items-center justify-content-between">

                  <h4 className="fw-bold ">Payment Log List</h4>
                </div>

                <div class="table-responsive">
                  <table class="table text-nowrap">
                    <thead>
                      <tr>
                        <th>Booking ID</th>
                        <th>Booking Dates</th>
                        <th>Pickup and drop</th>
                        <th>Payment</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList?.map((item) => (
                        <>

                          {item?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails?.map((payment) => (
                            <tr>
                              {console.log(payment)}
                              <th>
                                #{item.id}
                              </th>
                              <td>
                                From {moment(item.tripStartDate).format('DD MMM YY')} <br /> to {moment(item.tripEndDate).format('DD MMM YY')}
                              </td>
                              <td>
                                <span>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                                <span>{item?.BookingDetails[0].dropLocation.addressLine}</span>

                              </td>
                              <td>Rs. {payment.transactionData.razorpay_payment_id ? payment.amount / 100 : payment.amount}</td>
                              <td>{payment.transactionData.razorpay_payment_id ? 'Advance' : 'Final Payment'}</td>
                              <td><span class="badge bg-success  text-capitalize">{payment.paymentStatus}</span></td>
                            </tr>
                          ))}
                        </>))}

                    </tbody>
                  </table>
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
