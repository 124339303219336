import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";

import constant from "../../constant";
export default function MasterCarsList() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const imageBaseURL = constant.BASE_URL_UPLOADS;


  // Function to handle the toggle of the isActive property

  const [columnDefs] = useState([
    { field: "name", filter: true,headerName: "Car Name", },
    {
      headerName: "Image",
      field: "photos",
      filter: false,
      cellRenderer: (params) => {
        const image = params?.data?.photos;
        const imageUrl = constant.IMAGE_URL + image[0];
        return (
          <div>
            <img
              crossOrigin="anonymous"
              src={imageUrl}
              alt="CategoryImage"
              width={40}
              height={40}
            />
          </div>
        );
      },
    },
    {
      headerName: "Car Type",
      field: "type",
      filter: false,
    },
    {
      headerName: "Car brand",
      field: "brand",
      filter: false,
    },
    {
      headerName: "Car Capacity",
      field: "customerCapacity",
      filter: false,
    },
    {
      headerName: "Per KM Charge",
      field: "perKmCharge",
      filter: false,
    },

  

    {
      field: "isActive",
      filter: false,
      cellRenderer: (params) => {

        return (
          <div className="form-check form-switch">
            <input

              className="form-check-input mt-2"
              type="checkbox"
              checked={params?.data?.isActive === true}
              onChange={(e) => handleToggle(params.data.id, e.target.checked, 'isActive')}
            />
          </div>
        );
      },

    },
    {
      field: "action",
      filter: false,
      cellRenderer: (params) => {
        return (
          <>
            <Link to={`/edit-master-cars/${params.data.id}`}>
              <i
                className={`bx bx-edit bx-xs`}
                style={{ marginRight: "8px", cursor: "pointer" }}
              ></i>
            </Link>
          </>
        );
      },
    },



  ]);


  const handleToggle = async (id, isActive, key) => {


    const data = {}
    data[key] = isActive

    const response = await services.Cars.UPDATE_CAR(id, data)
    if (response) {
      getListHandler();
    }
  };
  const autoSizeStrategy = {
    type: 'fitGridWidth',
    defaultMinWidth: 100,
    columnLimits: [
        {
            colId: 'country',
            minWidth: 900
        }
    ]
};
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  //teacher list get
  const getListHandler = async () => {
    try {
      const response = await services.Cars.GET_CAR();
     let data =  response.data.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      //  setRowData(response.data)

      setRowData(data);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListHandler();
  }, []);


  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Master Cars List</h4>
                  <Link className="btn btn-primary me-2" to={"/add-master-cars"}>
                    Add Car
                  </Link>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple"
                    autoSizeStrategy={autoSizeStrategy} // Options - allows click selection of rows
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
