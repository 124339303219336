/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GENERATE_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.GENERATE_OTP(),
          data
        );

        //
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_USER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.UPDATE(),
          data
        );

        //
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  LOGIN_WITH_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.LOGIN_WITH_OTP(),
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data?.tokens?.access?.token) {
          localStorage.setItem('access_token', response.data.tokens.access.token)
        }

        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("access_token")}`;
        resolve(response);
      } catch (err) {
     
        reject(err);
      }
    });
  },
  VERIFY_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Login.OTP(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_SYSTEM_CONFIG: (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Auth.UPDATE_SYSTEM_CONFIG(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_SYSTEM_CONFIG: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Auth.GET_SYSTEM_CONFIG(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  RESEND_OTP: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.ResendOTP.RESENDOTP(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  FORGOT_PASSWORD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Login.FORGOTPASSWORD(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  RESET_PASSWORD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.ResetPassword.RESETPASSWORD(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
 
};
