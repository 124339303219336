import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../asset/Logo Main.png";
import { Icon } from "@iconify/react";
function SideBar() {
  let navigate = useNavigate();
  const logOutHandler = () => {
    localStorage.clear();
    navigate("/");
  };

  const [pageName, setPageName] = useState("");
const role = localStorage.getItem('role')
  useEffect(() => {
    setPageName(window.location.href);
  }, []);
  function closeSideBar() {
    document.querySelector("#layout-menu").classList.remove("expand-sidebar");
    document.getElementById("layout-menu-toggle").classList.remove("d-none");
    document.getElementById("layout-menu-toggle").classList.remove("d-none");
    document.getElementById("layout-menu-close").classList.add("d-none");
  }

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <span
          style={{ display: "flex", alignItems: "center", fontSize: "18px" }}
        >
          <img
            src={logo}
            height={55}
            alt=""
            width={55}
            style={{ marginRight: "5px" }}
          />
          <b>Cars53 Admin</b>
        </span>
        <Link
          to="javascript:void(0);"
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </Link>
      </div>
      <Link
        to=""
        className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
      >
        {/* <i className="bx bx-chevron-left bx-sm align-middle"></i> */}
      </Link>

      <span
        id="layout-menu-close"
        className="layout-menu-toggle menu-link text-large ms-auto d-xl-none d-none"
        onClick={() => closeSideBar()}
      >
        <i className="bx bx-chevron-left bx-sm align-middle"></i>
      </span>
      <div className="menu-inner-shadow"></div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
       {role == 'admin' && <> <li
          className={[
            "menu-item",
            pageName.includes("/dashboard") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/dashboard" className="menu-link">
            <i className="menu-icon tf-icons bx bx-chart"></i>
            <div data-i18n="Basic">Dashboard</div>
          </Link>
        </li>
        <li
          className={[
            "menu-item",
            pageName.includes("/users") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/users" className="menu-link">
            <i className="menu-icon tf-icons bx bx-user"></i>
            <div data-i18n="Basic">Users</div>
          </Link>
        </li>
        <li
          className={[
            "menu-item",
            pageName.includes("/subadmins") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/subadmins" className="menu-link">
            <i className="menu-icon tf-icons bx bx-user-circle"></i>
            <div data-i18n="Basic">Sub admins</div>
          </Link>
        </li></>}
       
        <li
          className={[
            "menu-item",
            pageName.includes("/master-cars") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/master-cars" className="menu-link">
            <i className="menu-icon tf-icons bx bx-list-ul"></i>

            <div data-i18n="Basic">Master Cars </div>
          </Link>
        </li>
       
        <li
          className={[
            "menu-item",
            pageName.includes("/vendors") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/vendors" className="menu-link">
            <i className="menu-icon tf-icons bx bx-shape-polygon"></i>

            <div data-i18n="Basic">Vendors </div>
          </Link>{" "}
        </li>
        <li
          className={[
            "menu-item",
            pageName.includes("/drivers") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/drivers" className="menu-link">
            <i className="menu-icon tf-icons bx bx-user"></i>

            <div data-i18n="Basic">Drivers </div>
          </Link>
        </li>
       {role == 'admin' && <li
          className={[
            "menu-item",
            pageName.includes("/system-config") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/system-config" className="menu-link">
            <i className="menu-icon tf-icons bx bx-user"></i>

            <div data-i18n="Basic">System Config </div>
          </Link>
        </li>}
        
        <li
          className={[
            "menu-item",
            pageName.includes("/orders") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/orders" className="menu-link">
            <i className="menu-icon tf-icons bx bx-cart"></i>
            <div data-i18n="basic">Order</div>
          </Link>
        </li>
        
       
        <li
          className={[
            "menu-item",
            pageName.includes("/Contact") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/Contact" className="menu-link">
            <Icon icon="bx:phone" hFlip={false} width={22} />
            <div className="ms-2" data-i18n="basic">
              Contact Us
            </div>
          </Link>
        </li>
       
        <li
          className={[
            "menu-item",
            pageName.includes("/payment") ? "active" : "",
          ].join(" ")}
        >
          <Link to="/payment" className="menu-link">
            <i className="menu-icon tf-icons bx bx-credit-card"></i>
            <div data-i18n="basic">Payment Log</div>
          </Link>
        </li>
        <li
          className={[
            "menu-item",
            pageName.includes("/logOUT") ? "active" : "",
          ].join(" ")}
          onClick={logOutHandler}
        >
          <Link to="/" className="menu-link">
            <i className="menu-icon tf-icons bx bx-log-out"></i>
            <div data-i18n="Basic">LogOut</div>
          </Link>
        </li>
      </ul>
    </aside>
  );
}
export default SideBar;
