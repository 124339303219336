import React from "react";

import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import moment from "moment/moment";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";
import { Link } from "react-router-dom";

export default function UsersList() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [columnDefs] = useState([
    {
      headerName: "User Id",
      filter: true,
      valueGetter: (params) => params?.data?.id,
    },
    { field: "firstName", filter: true },
    { field: "lastName", filter: true },
    { field: "phoneNumber", filter: true },
    { field: "email", filter: true },
    // { field: 'emailVerify', filter: true },
    // { field: 'addressId', filter: true },

    { field: "role", filter: true },

    {
      headerName: "Date of Birth",
      field: "bod",
      filter: true, 
      cellRenderer: (params) => {
        const Date = params?.data?.dob;

        return <div> {Date ? moment(Date).format("DD MMM YYYY") : "NA"}</div>;
      },
    },

    //  { field: 'status', filter: false },

    //  { headerName: 'status',filter: true, valueGetter: (params) => params?.data?.status },
    {
      headerName: "Verification Status",
      field: "Status",
      filter: false, // Replace 'field' with the actual field name from your data
      cellRenderer: (params) => {
        const status = params.value; // Get the 'status' value from the data
        const isComplete = status === true;

        return (
          <div>
            {isComplete ? (
              <span className="badge bg-warning">inactive</span>
            ) : (
              <span className="badge bg-success">active </span>
            )}
          </div>
        );
      },
    },
    

    {
      field: "Action",
      filter: false,
      cellRenderer: (params) => {
        return (
          <>
            <Link to={`/addressDetails/${params.data.id}`}>
              <i
                className={`bx bx-show bx-xs`}
                style={{ cursor: "pointer" }}
              ></i>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
          </>
        );
      },
    },

    {
      field: "isActive",
      filter: false,
      cellRenderer: (params) => {
        //console.log("=++++++++++++++++++++++++++++++",params.data.isActive)
        return (
          <div className="form-check form-switch">
            <input
              className="form-check-input mt-2"
              type="checkbox"
              checked={params?.data?.isActive === true}
              onChange={(e) =>
                handleToggle(params.data.id, e.target.checked, "isActive")
              }
            />
          </div>
        );
      },
    },

    
  ]);
  const handleToggle = async (id, isActive, key) => {
    const data = {};
    data[key] = isActive;
    console.log(data);
    const response = await services.Users.UPDATE_USERS(id, data);
    if (response) {
      getUserListHandler();
    }
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: 200,
    }),
    []
  );

  const getUserListHandler = async () => {
    try {
      const response = await services.Users.GET_USERS();

      setRowData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserListHandler();
  }, []);

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Users List </h4>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
