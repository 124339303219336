import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
 
  GET_PAYMENT: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.paymentLog.GET_PAYMENT()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}