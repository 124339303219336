import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";

function SystemConfig() {
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [kmPercent, setKMPercent] = useState();
  const [KmValue, setKMValue] = useState();
  const [rewardId, setRewardId] = useState();





  const OnSubmithandle = async () => {


    const data = {
        "key": "reward",
        "data": {
            "kmPercent": kmPercent,
            "kmValue": KmValue
        }
    };
    try {
      const response = await services.auth.UPDATE_SYSTEM_CONFIG(rewardId, data);
      if (response) {
        getSystemConfig();
        setToaster({

          type: "success",
          title: "Successful",
          text: "Value Updated Successfully",
          visiblity: "show",
        });
        setTimeout(() => {
            setToaster({

                type: "success",
                title: "Successful",
                text: "Value Updated Successfully",
                visiblity: "hide",
              });
        }, 1500);
      }
    } catch (error) {

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error.response.data.message,
        visiblity: "show",
      });
      setTimeout(() => {
        setToaster({
          visiblity: "hide",
        });
      }, 1500);
    }

    //navigate student list
  };

 
  const getSystemConfig = async () => {
    try {
        const response = await services.auth.GET_SYSTEM_CONFIG();
        setKMPercent(response.data.data.kmPercent);
        setKMValue(response.data.data.kmValue);
        setRewardId(response.data.id);
  

    } catch (error) {
        console.log(error)
    }
}
  useEffect(()=>{
    getSystemConfig()
  },[])
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Edit System Config</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div
                        className="card-body"
                        style={{ margin: "16px", padding: "10px" }}
                      >
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-35px" }}
                        >
                        
                        <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              KM Percent Reward{" "}
                            </label>
                            <input
                            type="number"
                              className="form-control"
                              placeholder="Enter Value"
                              value={kmPercent}
                              onChange={(e) => {
                                setKMPercent(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                             KM Value{" "}
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter Value"
                              value={KmValue}
                              type="number"
                              onChange={(e) => {
                                setKMValue(e.target.value);

                              }}
                            />

                          </div>
                          <div className="col-md-4 mt-4">
                            <button onClick={() => OnSubmithandle()} className="btn btn-primary">Submit</button>
                          </div>
                        
                         
                        

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default SystemConfig;
