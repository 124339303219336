import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";

import constant from "../../constant";
export default function SubAdminList() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const imageBaseURL = constant.BASE_URL_UPLOADS;


  // Function to handle the toggle of the isActive property
  const [columnDefs] = useState([
    {
      headerName: "User Id",
      filter: true,
      valueGetter: (params) => params?.data?.id,
    },
    { field: "firstName", filter: true },
    { field: "lastName", filter: true },
    { field: "phoneNumber", filter: true },
    { field: "email", filter: true },
   
    { field: "role", filter: true },


    
    

    {
      field: "Action",
      filter: false,
      cellRenderer: (params) => {
        return (
          <>
            <Link to={`/edit-subadmin/${params.data.id}`}>
              <i
                className={`bx bx-show bx-xs`}
                style={{ cursor: "pointer" }}
              ></i>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
          </>
        );
      },
    },

    {
      field: "isActive",
      filter: false,
      cellRenderer: (params) => {
        //console.log("=++++++++++++++++++++++++++++++",params.data.isActive)
        return (
          <div className="form-check form-switch">
            <input
              className="form-check-input mt-2"
              type="checkbox"
              checked={params?.data?.isActive === true}
              onChange={(e) =>
                handleToggle(params.data.id, e.target.checked, "isActive")
              }
            />
          </div>
        );
      },
    },

    
  ]);


  const handleToggle = async (id, isActive, key) => {


    const data = {}
    data[key] = isActive

    const response = await services.Users.TOGGLE_USER(id, data)
    if (response) {
      getUserListHandler();
    }
  };
  const autoSizeStrategy = {
    type: 'fitGridWidth',
    defaultMinWidth: 100,
    columnLimits: [
        {
            colId: 'country',
            minWidth: 900
        }
    ]
};
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const getUserListHandler = async () => {
    try {
      const response = await services.Users.GET_ALL_USERS();
      var newArray = response.data.filter(function (el) {
        return el.role == 'subadmin'
              
      });
      setRowData(newArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserListHandler();
  }, []);


  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Sub Admins List</h4>
                  <Link className="btn btn-primary me-2" to={"/add-subadmin"}>
                    Add Sub Admin
                  </Link>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple"
                    autoSizeStrategy={autoSizeStrategy} // Options - allows click selection of rows
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
