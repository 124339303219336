import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CREATE_VENDOR: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Vendor.CREATE_VENDOR(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_VENDOR: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_VENDOR()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_VENDOR_CAR: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_VENDOR_CAR(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_DRIVER: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_DRIVER()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_VENDOR_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_VENDOR_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_DRIVER_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Vendor.GET_DRIVER_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_VENDOR: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Vendor.UPDATE_VENDOR(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_DRIVER: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Vendor.UPDATE_DRIVER(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },


};
