import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

function Addproduct() {
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [defaultContentSet, setDefaultContentSet] = useState(false);
  const [status, setStatus] = useState();
  useEffect(() => {
    GET_CMS();
  }, []);
  const GET_CMS = async () => {
    try {
      const response = await services.CMS.GET_CMS();
      let add = response.data.data.rows[1].html.replace(/&lt;/g, "<");
      setAdditionalInformation(add);
      setStatus(response.data.data.rows[1].status);
    } catch (e) {
      console.error(e);
    }
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const htmlContent = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );
    setAdditionalInformation(htmlContent);
  };
  console.log(additionalInformation);
  const OnSubmithandle = async (a) => {
    let data;
    a == "status"
      ? (data = {
          name: "CONTACT_CMS",
          status: status,
        })
      : (data = {
          name: "CONTACT_CMS",
          html: additionalInformation,
          status: status,
        });
    try {
      const response = await services.CMS.UPDATE_CMS(data);
      if (response) {
        GET_CMS();

        if (a == "Data") {
          setToaster({
            type: "success",
            title: "Successful",
            text: "CMS Updated",
            visiblity: "show",
          });
          setTimeout(() => {
            setToaster({
              visiblity: "hide",
            });
          }, 1500);
        }
      }
    } catch (e) {
      console.error(e);

      if (a == "Data") {
        setToaster({
          type: "danger",
          title: "Error Occured",
          text: e?.response?.data?.message,
          visiblity: "show",
        });
      }
    }
  };

  useEffect(() => {
    OnSubmithandle("status");
  }, [status]);

  const setDefaultContent = (content) => {
    const html = content;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setDefaultContentSet(true);
    }
  };

  useEffect(() => {
    if (additionalInformation && !defaultContentSet) {
      setDefaultContent(additionalInformation);
    }
  }, [additionalInformation, defaultContentSet]);
  const handleToggle = (value) => {
    setStatus(value);
  };
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
            <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">CMS</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div className="card-body" style={{ margin: "5px" }}>
                        <div className="d-flex justify-content-start">
                          <div>Status</div>
                          <div className="ms-2 form-check form-switch">
                            <input
                              className="form-check-input  text-end"
                              type="checkbox"
                              checked={status}
                              onChange={(e) => {
                                handleToggle(e.target.checked);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-20px" }}
                        >
                          <label
                            htmlFor="defaultFormControlInput"
                            className="form-label mt-5"
                          >
                            Content
                          </label>
                          <div>
                            <Editor
                              editorState={editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={onEditorStateChange}
                            />
                          </div>

                          <div className="col-md-4 mt-4 ">
                            <button
                              type="submit"
                              onClick={() => OnSubmithandle("Data")}
                              className="btn btn-primary ml-md-10"
                            >
                              Save Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addproduct;
