/*eslint-disable import/no-anonymous-default-export*/
import auth from "./auth";
import order from "./order";
import Cars from "./masterCars";
import Users from "./users";
import Vendor from "./vendor";
import paymentLog from "./paymentLog";

export default {
  auth,
  order,
  Cars,
  Users,
  Vendor,
  paymentLog,

};
