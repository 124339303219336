/* eslint-disable jsx-a11y/anchor-is-valid */
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import moment from "moment/moment";
import constant from "../../constant";
import UserOrderDetails from "./UserOrderDetails";
import Userdetails from "./Userdetails";
import { hover } from "@testing-library/user-event/dist/hover";
function AddressDetails() {
  const { id } = useParams();
  const imageBaseURL = constant.BASE_URL_UPLOADS;
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [useDetails, setUserDetails] = useState("");
  const [activeIndex, setActiveIndex] = useState(1);
  // get category data

  const getAddress = async () => {
    try {
      const response = await services.Users.GET_USERS_BY_ID(id);
      if (response) {
        setUserDetails(response?.data?.data?.Addresses);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAddress();
  }, []);

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />
            <div className="tab-style3  ">
              <ul
                className="nav nav-tabs text-uppercase "
                style={{ marginLeft: "27%", marginTop: "2%" }}
              >
                <li className="nav-item ">
                  <a
                    className={
                      activeIndex === 1 ? "nav-link active" : "nav-link"
                    }
                    style={{ cursor: "pointer" }}
                    id="Address Details"
                    data-bs-toggle="tab"
                    onClick={() => handleOnClick(1)}
                  >
                    Address Details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      activeIndex === 2 ? "nav-link active" : "nav-link"
                    }
                    style={{ cursor: "pointer" }}
                    id="User order Details"
                    data-bs-toggle="tab"
                    onClick={() => handleOnClick(2)}
                  >
                    User order Details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      activeIndex === 3 ? "nav-link active" : "nav-link"
                    }
                    style={{ cursor: "pointer" }}
                    id="User Details"
                    data-bs-toggle="tab"
                    onClick={() => handleOnClick(3)}
                  >
                    User Details
                  </a>
                </li>
              </ul>
            </div>

            <div className="tab-content shop_info_tab entry-main-content">
              <div
                className={
                  activeIndex === 1
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="Address Details"
              >
                <div className="content-wrapper">
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="d-flex py-3 align-items-center justify-content-between">
                      <h4 className="fw-bold ">Address Details</h4>
                    </div>

                    {useDetails &&
                      useDetails.length > 0 &&
                      useDetails.map((address, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-12">
                            <div className="card mb-3">
                              <div className="card-body">
                                <div className="row align-items-end">
                                  <div className="col-md-2 mt-2">
                                    <small>User Id</small>
                                    <h6>{address?.userId}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>address</small>
                                    <h6>{address?.address?.address}</h6>
                                  </div>

                                  <div className="col-md-3 mt-2">
                                    <small>house No</small>
                                    <h6>{address?.address?.houseNo}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>city</small>
                                    <h6>{address?.address?.city}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>full Name</small>
                                    <h6>{address?.address?.fullName}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>Phone Number</small>
                                    <h6>{address?.address?.phoneNumber}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>pinCode</small>
                                    <h6>{address?.address?.pinCode}</h6>
                                  </div>

                                  <div className="col-md-2 mt-2">
                                    <small>state</small>
                                    <h6>{address?.address?.state}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <footer className="content-footer footer bg-footer-theme">
                    <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                      <div className="mb-2 mb-md-0">© Cars53</div>
                    </div>
                  </footer>
                </div>
              </div>

              <div
                className={
                  activeIndex === 2
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="User order Details"
              >
                <UserOrderDetails />
              </div>
              <div
                className={
                  activeIndex === 3
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="User Details"
              >
                <Userdetails />
              </div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default AddressDetails;
