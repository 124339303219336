import React from "react";

import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import moment from "moment/moment";
import { Link } from "react-router-dom";
export default function Drivers() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  const [columnDefs] = useState([
    { field: 'firstName', headerName: "First Name", filter: true },
    { field: 'lastName', headerName: "Last Name", filter: true },
    { field: 'phoneNumber', headerName: 'Phone Number', filter: true },
    { field: "email", filter: true },
    { field: 'DriverDetail', headerName: 'Aadhar Number',filter: true,cellRenderer: (params) => {return(params.data.DriverDetail.aadharNumber)}},
    { field: 'DriverDetail', headerName: 'Driving License Number',filter: true,cellRenderer: (params) => {return(params.data.DriverDetail.drivingLicenceNumber)}},
    {
      headerName: "Date",
      field: "Date",
      filter: true,
      cellRenderer: (params) => {
        const Date = params?.data?.createdAt;
        // Get the 'status' value from the data

        return <div>{moment(Date).format("DD MMM YYYY")}</div>;
      },
    },
    { field: 'DriverDetail', headerName: 'Approval Status',filter: true,cellRenderer: (params) => {return(<div className={`badge bg-${params.data.DriverDetail.approvalStatus == 'approved' ? 'success' : 'primary'}`}>{params.data.DriverDetail.approvalStatus}</div>)}  },
    { field: 'isActive', headerName: 'Active Status',filter: true,cellRenderer: (params) => {return(<div className={`badge bg-${params.data.isActive ? 'success' : 'primary'}`}>{params.data.isActive ? 'Active' : 'Inactive'}</div>)}  },
    {
      field: 'action', filter: false,
      cellRenderer: (params) => {
          return (
       
         
              <Link to={`/editdriver/${params.data.id}`}>
              <i className={`bx bx-edit bx-xs`} style={{ marginRight: '8px', cursor: 'pointer' }}></i>
          </Link>
          );
      }
  },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: 150,
    }),
    []
  );
  const getDriverList = async () => {
    try {
      const response = await services.Vendor.GET_DRIVER();

      setRowData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };




  useEffect(() => {
    getDriverList();
  }, []);

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold">Drivers List</h4>
                </div>
                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                  />
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
