
import { Link, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from 'react';
import services from '../../services';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import constant from "../../constant";
function    VendorsCarList() {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const imageBaseURL = constant.IMAGE_URL;
    let { id } = useParams();
    const [columnDefs, ] = useState([
        
        { field: 'Car',headerName: "Type", filter: true,cellRenderer: (params) => {return(<span className="text-uppercase">{params.data.Car.type}</span>)}  },
        { field: 'Car', headerName: 'Car and Brand',filter: true,cellRenderer: (params) => {return(<Link to={`/edit-master-cars/${params.data.Car.id}`}>{params.data.Car.name+', '+params.data.Car.brand}</Link>)} },
        { field: 'Car', headerName: 'Customer Capacity',filter: true,cellRenderer: (params) => {return(params.data.Car.customerCapacity)} },
        { field: 'Car', headerName: 'Cost per KM',filter: true,cellRenderer: (params) => {return(params.data.Car.perKmCharge)} },
        { field: 'rcNumber', headerName: 'RC #',filter: true,cellRenderer: (params) => {return(params.data.rcNumber)} },
        { field: 'year', headerName: 'Model',filter: true,cellRenderer: (params) => {return(params.data.year)} },
        { field: 'Car', headerName: 'Car photo',filter: true,cellRenderer: (params) => {
            const image = params?.data?.photos;
            const imageUrl = constant.IMAGE_URL + image[0];
            return (
              <div>
                <img
                  crossOrigin="anonymous"
                  src={imageUrl}
                  alt="CategoryImage"
                  width={40}
                  height={40}
                />
              </div>
            );
          },},

          { field: 'DriverCar', headerName: 'Assigned Driver Name',filter: true,cellRenderer: (params) => {return(<>{params.data?.DriverCar?.User.id && <Link to={`/editdriver/${params.data.DriverCar?.User.id}`}>{params.data.DriverCar.User.firstName+" "+params.data.DriverCar?.User.lastName}</Link>}</>)} },
       
    ]);  
   



    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
        width: 170,
    }),[]);


    const getVendorCarListHandler = async (id) => {
        try {
            const response = await services.Vendor.GET_VENDOR_CAR(id)
    
            setRowData(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVendorCarListHandler(id)
    }, []);




    return (
        <div className="App">

            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <SideBar />
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="d-flex py-3 align-items-center justify-content-between">
                                    <h4 className="fw-bold ">Vendor's Car List</h4>
                                    
                                </div>
                                <div className="ag-theme-alpine"    style={{ width: "100%", height: "90%" }}>
                                    <AgGridReact
                                        ref={gridRef} // Ref for accessing Grid's API

                                        rowData={rowData} // Row Data for Rows


                                        columnDefs={columnDefs} // Column Defs for Columns
                                        defaultColDef={defaultColDef} // Default Column Properties

                                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                        rowSelection='multiple' // Options - allows click selection of rows
                                    />
                                </div>
                            </div>
                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                                    <div className="mb-2 mb-md-0">
                                        ©
                                       Cars53
                                    </div>
                                </div>
                            </footer>
                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>
                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
        </div>
    );
}

export default VendorsCarList;
