/*eslint-disable import/no-anonymous-default-export*/
export default {
  GET_USERS: () => "/admin/customers/getAllCustomers",
  ADD_USERS: () => "/admin/users/addUser",
  GET_ALL_USERS: () => "/admin/users/getAll",
  UPDATE_USERS: (id) => `/admin/users/update/${id}`,
  GET_USERS_BY_ID: (id) => `/admin/users/getById/${id}`,
  GET_USER_BY_GETALL: (id) => `/order/getUserByID/${id}`,
  GET_USER_BY_USERID: (id) => `/user/${id}`,
  TOGGLE_USER : (id) => `/admin/users/toggleUser/${id}`
};
