import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import "../../App.css";
import moment from "moment";
export default function Coupon() {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();

  const [columnDefs] = useState([
    { field: "id", headerName: "Id", filter: true , width: "100" },
    { field: "name", headerName: "Name", filter: true },
    { field: "email", headerName: "Email", filter: true },
    { field: "phoneNumber", headerName: "Phone Number", filter: true },
    { field: "subject", headerName: "Subject", filter: true },

    {
      headerName: "Created At",
      field: "createdAt",
      filter: true,
      width: "120",
      cellRenderer: (params) => {
        const Date = params?.data?.createdAt;

        return <div> {Date ? moment(Date).format("DD MMM YYYY") : "NA"}</div>;
      },
    },

    { field: "message", headerName: "Message", filter: true, width: "470" },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: "150",
    }),
    []
  );

  const getCategoryListHandler = async () => {
    try {
      const response = await services.Contact.Contact_as();
      setRowData(response?.data?.data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategoryListHandler();
  }, []);
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Contact Us</h4>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="multiple"
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}
