import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import moment from "moment/moment";
import { AgGridReact } from "ag-grid-react";
import { useParams } from "react-router-dom";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import Header from "../../components/header";
function UserOrderDetails() {
  const { id } = useParams();
  const gridRef = useRef();
  const [rowData, setRowData] = useState();

  const [columnDefs] = useState([
    // { field: "userId", filter: true },
    { field: "id", filter: true },

    {
      headerName: "Create Order",
      field: "createdAt",
      filter: true, // Replace 'field' with the actual field name from your data
      cellRenderer: (params) => {
        const Date = params?.data?.createdAt;
        // Get the 'status' value from the data

        return <div> {Date ? moment(Date).format("DD MMM YYYY") : "NA"}</div>;
      },
    },
    // { field: "id", filter: true },
    { field: "totalAmount", filter: true },
    // {
    //   headerName: "First Name",
    //   valueGetter: (params) => params?.data?.User?.firstName,
    // },
    // {
    //   headerName: "Last Name",
    //   valueGetter: (params) => params?.data?.User?.lastName,
    // },

    { field: "totalItems", filter: true },
    { field: "totalQuantity", filter: true },

    // {
    //   headerName: "createdAt",
    //   field: "createdAt",
    //   filter: true,
    //   cellRenderer: (params) => {
    //     const Date = params?.data?.createdAt;
    //     // Get the 'status' value from the data

    //     return <div>{moment(Date).format("DD MMM YYYY")}</div>;
    //   },
    // },

    // {
    //   headerName: "Order Stutas",
    //   field: "type",
    //   filter: true,
    //   cellRenderer: (params) => {
    //     const orders = params?.data?.OrderDetails;
    //     //all type Delivered
    //     const allDelivered = orders.every(
    //       (order) => order.type === "\r Delivered"
    //     );

    //     return (
    //       <div>
    //         {allDelivered ? <span>Delivered </span> : <span>On Process</span>}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   headerName: " Status",
    //   field: "status",
    //   filter: false, // Replace 'field' with the actual field name from your data
    //   cellRenderer: (params) => {
    //     const status = params.value; // Get the 'status' value from the data
    //     const isComplete = status === true;

    //     return (
    //       <div>
    //         {isComplete ? (
    //           <span className="badge bg-success">Complete</span>
    //         ) : (
    //           <span className="badge bg-warning">Incomplete</span>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      headerName: "Order Stutas",
      field: "orderStatus",
      filter: true,
    },

    {
      field: "Action",
      filter: false,
      cellRenderer: (params) => {
        return (
          <>
            <Link to={`/orderdetails/${params.data.id}`}>
              <i
                className={`bx bx-show bx-xs`}
                style={{ cursor: "pointer" }}
              ></i>
            </Link>
          </>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: 235,
    }),
    []
  );

  const getOrderListHandler = async () => {
    try {
      const response = await services.Users.GET_USER_BY_GETALL(id);
      console.log(response?.data?.data?.rows);
      setRowData(response?.data?.data?.rows);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOrderListHandler();
  }, []);
  console.log("Order", rowData);
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">User Order Details</h4>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={rowData} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="multiple" // Options - allows click selection of rows
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default UserOrderDetails;
