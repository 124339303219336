/* eslint-disable no-async-promise-executor */
import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_BOOKING_DETAIL: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.GET_BOOKING_DETAIL(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },



  GET_BOOKING: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.GET_BOOKING()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};
