import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import moment from "moment/moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
function Dashboard() {
  const [rowData, setRowData] = useState();
  const getOrderListHandler = async () => {
    try {
      const response = await services.order.GET_BOOKING();
      const vendors = await services.Vendor.GET_VENDOR()
      let data = response.data;
      let confirmed = data.filter(function (el) {
        return el.BookingDetails[0].bookingStatus == 'confirmed' || el.BookingDetails[0].bookingStatus == 'accepted';
      });
      let ongoing = data.filter(function (el) {
        return el.BookingDetails[0].bookingStatus == 'started';
      });
      let pending = data.filter(function (el) {
        return el.BookingDetails[0].bookingStatus == 'pending';
      });
      let completed = data.filter(function (el) {
        return el.BookingDetails[0].bookingStatus == 'ended';
      });
      let totalOnline = 0
      let totalCash = 0
      let total = 0
      completed.map((item) => {
        item?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails?.map((payment) => {
          if (payment.paymentStatus == 'success') {
            if (payment.transactionData.razorpay_payment_id) {
              total = total + (payment.amount / 100);
              totalOnline = totalOnline + (payment.amount / 100);
            } else {
              total = total + payment.amount;
              totalCash = totalCash + payment.amount;
            }
          }
        })
      })
      setRowData({ pending, ongoing, completed, confirmed, total, totalCash, totalOnline, vendors: vendors.data.length });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderListHandler();
  }, []);
  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />


            <div className="content-wrapper">
            {rowData &&  <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Dashboard</h4>
                </div>
                <div className="row g-6">

                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-primary">
                              <i className="bx bxs-truck bx-lg" />
                            </span>
                          </div>
                          
                          <h4 className="mb-0">{rowData.ongoing.length}</h4>
                        </div>
                        {/* <p className="mb-2">On route vehicles</p> */}
                        <p className="mb-0">

                          <span className="text-muted">On going trips</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-warning h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-warning">
                              <i className="bx bx-box bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">{rowData.confirmed.length}</h4>
                        </div>
                        {/* <p className="mb-2">Assigned Bookings</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">-8.7%</span> */}
                          <span className="text-muted">Confirmed and Accepted Bookings</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-danger h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-danger">
                              <i className="bx bx-check bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">{rowData.completed.length}</h4>
                        </div>
                        {/* <p className="mb-2">Deviated from route</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">+4.3%</span> */}
                          <span className="text-muted">Completed Trips</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-info h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-info">
                              <i className="bx bx-time-five bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">{rowData.pending.length}</h4>
                        </div>
                        {/* <p className="mb-2">Late vehicles</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">-2.5%</span> */}
                          <span className="text-muted">Pending Trips</span>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row g-6 mt-3">

                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-primary h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-primary">
                              <i className="bx bx-money bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">Rs. {rowData.total}</h4>
                        </div>
                        {/* <p className="mb-2">On route vehicles</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">+18.2%</span> */}
                          <span className="text-muted">Total payment recieved</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-warning h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-warning">
                              <i className="bx bx-mobile bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">Rs. {rowData.totalOnline}</h4>
                        </div>
                        {/* <p className="mb-2">Vehicles with errors</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">-8.7%</span> */}
                          <span className="text-muted">Online recieved</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-danger h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-danger">
                              <i className="bx bx-money bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">Rs. {rowData.totalCash}</h4>
                        </div>
                        {/* <p className="mb-2">Deviated from route</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">+4.3%</span> */}
                          <span className="text-muted">Cash Recieved</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="card card-border-shadow-info h-100">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <div className="avatar me-4">
                            <span className="avatar-initial rounded bg-label-info">
                              <i className="bx bx-time-five bx-lg" />
                            </span>
                          </div>
                          <h4 className="mb-0">{rowData.vendors}</h4>
                        </div>
                        {/* <p className="mb-2">Late vehicles</p> */}
                        <p className="mb-0">
                          {/* <span className="text-heading fw-medium me-2">-2.5%</span> */}
                          <span className="text-muted">My Vendors</span>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>


              </div>}

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard;