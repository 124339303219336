import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from "react";
import services from "../../services";
import moment from "moment/moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";

import { Rating } from 'react-simple-star-rating'
function OlrderList() {
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs] = useState([
    {
      field: "Trip Type", cellRenderer: (params) => {

        return <div className="text-capitalize">{params?.data?.sharingAvailable > 0 ? 'Sharing' : params?.data?.tripType} </div>;
      },
    },
    {
      field: "User Details", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}>{params?.data?.BookingDetails[0].customerData?.name} <br /> {params?.data?.BookingDetails[0].customerData?.email} <br /> {params?.data?.BookingDetails[0].customerData?.phoneNumber}</div>;
      },
    },
    {
      headerName: "Vendor details"
      , cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}><Link to={`/editvendor/${params?.data?.vendorId}`}>{params?.data?.vendorData?.firstName}  {params?.data?.vendorData?.lastName}</Link><br /> {params?.data?.vendorData?.phoneNumber} <br /> {params?.data?.vendorData?.email}</div>;
      },
    },
    {
      headerName: "Master Car", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}><Link to={`/edit-master-cars/${params?.data?.Car.id}`}>{params?.data?.Car.brand}  {params?.data?.Car.name}</Link><br /> {params?.data?.Car.perKmCharge} per KM <br /> {params?.data?.Car.type}</div>;
      },
    },
    {
      headerName: "Vendor Car", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}>{params?.data?.Car.VendorCars.map((item) => {
          if (item.id == params?.data?.vendorCarId) {
            return (<div>{item.rcNumber}<br /> Regst. year {item.year}</div>)
          }
        })}</div>;
      },
    },
    {
      headerName: "Driver Details", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}><Link to={`/editdriver/${params?.data?.driverData?.id}`}>{params?.data?.driverData?.firstName}  {params?.data?.driverData?.lastName}</Link><br /> {params?.data?.driverData?.email} <br /> {params?.data?.driverData?.phoneNumber}</div>;
      },
    },
    {
      headerName: "Booking Date", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}>{moment(params.data.createdAt).format('DD, MMM, YY')}</div>;
      },
    },
    {
      headerName: "Pickup & drop locations", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}>{params?.data?.BookingDetails[0]?.pickupLocation.addressLine} <br /> {params?.data?.BookingDetails[0]?.dropLocation.addressLine}</div>;
      },
    },
    {
      headerName: "Booking Status", cellRenderer: (params) => {
       
        return <div className={`badge badge-primary ${params?.data.BookingDetails[0]?.bookingStatus == 'pending'? 'bg-primary' : 'bg-success'}`}>{params?.data.BookingDetails[0]?.bookingStatus}</div>;
      },
    },
    {
      headerName: "Review Rating", cellRenderer: (params) => {
       
        return <div style={{ lineHeight: 1.5 }}>
         {params.data.BookingReviews?.length > 0 &&<> <Rating size={25} initialValue={params.data.BookingReviews[0].rating}/> <br/>
          {params.data.BookingReviews[0].comment}</>}</div>
      },
    },
    {
      headerName: "Estimated Price", cellRenderer: (params) => {

        return <div style={{ lineHeight: 1.5 }}>Rs. {(params?.data?.Car.perKmCharge * (params?.data?.distanceBreakdown.distance.value/1000)).toFixed(0)}</div>;
      },
    },
    
    {
      headerName: "Actual Payment", cellRenderer: (params) => {
        let result = 0
        if(params?.data.BookingDetails[0]?.bookingStatus == 'ended'){
          
         result = params?.data.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails.reduce(function (acc, obj) { if(obj.paymentStatus == 'success'){ return acc + (obj.transactionData.mealCost ? obj.amount : obj.amount/100)}; }, 0);
        }
        return <div style={{ lineHeight: 1.5 }}>Rs. {result ? result : 0}</div>;
      },
    },
    
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      width: 180,
    }),
    []
  );

  const getOrderListHandler = async () => {
    try {
      const response = await services.order.GET_BOOKING();

      setRowData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderListHandler();
  }, []);

  return (
    <div className="App">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />


            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Order List</h4>
                </div>

                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: "90%" }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowHeight={70}
                    rowSelection="multiple"
                  />
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OlrderList;
