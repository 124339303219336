import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import moment from "moment/moment";
import Toaster from "../../components/toaster";
import constant from "../../constant";
import { useNavigate } from "react-router-dom";
function OrderDetails() {
  const { id } = useParams();
  const imageBaseURL = constant.BASE_URL_UPLOADS;
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [order, setOrder] = useState("");
  const [useDetails, setUserDetails] = useState("");
  const navigate = useNavigate();
  const [Failed, setFailed] = useState("");
  const [AddressId, setAddressId] = useState("");
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const GET_BY_ID_ORDER = useCallback(async () => {
    const response = await services.order.GET_ORDER_BY_ID(id);
    console.log(response?.data?.data?.orderDetails);
    if (response) {
      setOrderDetails(response?.data?.data?.OrderDetails);
      setFailed(response?.data?.data.orderStatus);
      setProductDetails(response?.data?.data?.orderDetails);
      setUserDetails(response?.data?.data?.User);
      setAddressId(response?.data?.data?.addressId);
    }
  }, [id]);

  useEffect(() => {
    GET_BY_ID_ORDER();
  }, [GET_BY_ID_ORDER]);
  const OnSubmithandle = async (id) => {
    let isload = true;
    // if (orderError) {
    //   isload = false;
    // }

    const data = {
      type: order,
    };

    if (order) {
      try {
        const response = await services.order.UPDATE_ORDER(id, data);
        console.log(response);
        if (response) {
          setToaster({
            type: "success",
            title: "Successful ",
            text: "Status Changed Successfully",
            visiblity: "show",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
          GET_BY_ID_ORDER();
        }
      } catch (error) {
        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error.response.data.message,
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
        console.log(error);
      }
    }
  };
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Order Details</h4>
                </div>

                {orderDetails &&
                  orderDetails.length > 0 &&
                  orderDetails?.map((Details) => {
                    const dataOfMargin = Details?.detail;

                    const basePrice =
                      dataOfMargin?.basePrice * dataOfMargin?.length +
                      dataOfMargin?.marginAmount;
                    const percentOfmargin =
                      (100 - dataOfMargin.discountPercentage) / 100;
                    const MRP = basePrice / percentOfmargin;
                    console.log("MRP", MRP);
                    return (
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="row align-items-end">
                                <div className="col-md-2 mt-2">
                                  <small>Order Id</small>

                                  <h6>{Details?.id}</h6>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>is Active</small>
                                    <h6>
                                      {Details.isActive === true
                                        ? "True"
                                        : "Flase"}
                                    </h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>MRP</small>
                                    <h6>{MRP.toFixed(2)}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Discount</small>
                                    <h6>{dataOfMargin?.discountPercentage}%</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small> Amount</small>
                                    <h6>{Details.amount}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small> Status</small>
                                    <h6>
                                      {Failed !== "Payment Failed" ? (
                                        <>
                                          {" "}
                                          <span className="badge bg-success">
                                            {Details?.type}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          Payment Failed
                                        </span>
                                      )}
                                    </h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Tracking Id</small>
                                    <h6>{Details.trackingId}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Tracking Link</small>
                                    <h6>{Details.trackingLink}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>product Id</small>
                                    <h6>{Details.productId}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Total Quantity</small>
                                    <h6>{Details.totalQuantity}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div class="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Type</small>
                                    <h6>{Details.type}</h6>
                                  </div>
                                </div>
                                <div className="col-md-2 mt-2">
                                  <div className="vx-col sm:w-1/6 w-full mx-0">
                                    <small>Created At</small>
                                    <h6>
                                      {moment(Details.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </h6>
                                  </div>
                                </div>
                                {Failed !== "Payment Failed" && (
                                  <>
                                    {" "}
                                    <div className="row ">
                                      <div className="col-md-16">
                                        <div
                                          className="card mb-4"
                                          style={{ margin: "-12px" }}
                                        >
                                          <div
                                            className="card-body"
                                            style={{
                                              margin: "5px",
                                              padding: "10px",
                                            }}
                                          >
                                            <div
                                              className="row align-items-end"
                                              style={{ marginTop: "-40px" }}
                                            >
                                              <div className="col-md-4 mt-4">
                                                <label
                                                  htmlFor="defaultFormControlInput"
                                                  className="form-label"
                                                >
                                                  Order Status
                                                </label>

                                                <select
                                                  className="form-select"
                                                  id="schoolyearSelect"
                                                  // value={order}
                                                  onChange={(e) => {
                                                    setOrder(
                                                      e.target.value.trim()
                                                    );
                                                    // console.log(e.target.value);
                                                    // if (e.target.value.trim()) {
                                                    //   // setOrder(e.target.value);
                                                    // }
                                                    // if (e.target.value.length === 0) {
                                                    //   // setOrderError("Required");
                                                    // } else {
                                                    //   // setOrderError("");
                                                    // }
                                                  }}
                                                  onClick={() => {
                                                    OnSubmithandle(Details?.id);
                                                    // console.log("OnClick",  Details?.orderId)
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Order Type
                                                  </option>
                                                  <option value="Delivered">
                                                    Delivered
                                                  </option>
                                                  <option value="Refund">
                                                    Refund
                                                  </option>
                                                  <option value="Cancel">
                                                    Cancel
                                                  </option>
                                                  <option value="In Process">
                                                    In Process
                                                  </option>
                                                </select>
                                                {/* {orderError && (
            <>
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  position: "absolute",
                }}
              >
                {orderError}
              </span>
            </>
          )} */}
                                              </div>

                                              {/* <div className="col-md-4 mt-4 ">
          <button
            type="submit"
            // disabled={!order}
            onClick={() => {
              OnSubmithandle(
                Details?.id,
                Details?.orderId
              );
            }}
            className="btn btn-primary ml-md-10"
          >
            Save
          </button>
        </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}{" "}
                                {/* <div className="col-md-2 mt-2"> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <hr />

                <h4 className="fw-bold ">User Details</h4>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-2 mt-2">
                            <small>Address Id</small>

                            <h6>{AddressId}</h6>
                          </div>

                          <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>Date of Birth</small>
                              <h6>
                                {" "}
                                {moment(useDetails?.dob).format("DD MMM YYYY")}
                              </h6>
                            </div>
                          </div>

                          <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small> email</small>
                              <h6>{useDetails?.email}</h6>
                            </div>
                          </div>

                          {/* <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>Order Status</small>
                              <h6>
                                {useDetails.status ? (
                                  <>
                                    {" "}
                                    <span className="badge bg-success">
                                      Complete
                                    </span>
                                  </>
                                ) : (
                                  <span className="badge bg-warning">
                                    Incomplete
                                  </span>
                                )}
                              </h6>
                            </div>
                          </div> */}
                          <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>email Verify</small>
                              <h6>{useDetails.emailVerify}</h6>
                            </div>
                          </div>
                          <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>User Name</small>
                              <h6>
                                {useDetails?.firstName}&nbsp;
                                {useDetails?.lastName}
                              </h6>
                            </div>
                          </div>

                          <div className="col-md-2 mt-2">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>Phone Number</small>
                              <h6>{useDetails.phoneNumber}</h6>
                            </div>
                          </div>
                          {/* <div className="col-md-3 mt32">
                            <div class="vx-col sm:w-1/6 w-full mx-0">
                              <small>Role</small>
                              <h6>{useDetails.role}</h6>
                            </div>
                          </div> */}

                          <div className="col-md-2 mt-2">
                            <div className="vx-col sm:w-1/6 w-full mx-0">
                              <small>Created At</small>
                              <h6>
                                {moment(useDetails.createdAt).format(
                                  "DD MMM YYYY"
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h4 className="fw-bold"> Prodcut Details</h4>
                <hr />
                {/* ProdcutDetails */}
                {productDetails.length > 0 &&
                  productDetails?.map((Details) => (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            <div className="row align-items-end">
                              <div className="col-md-2 mt-2">
                                <small>Prodcut Id</small>

                                <h6>{Details?.id}</h6>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <img
                                    style={{ borderRadius: "10px" }}
                                    crossOrigin="anonymous"
                                    src={imageBaseURL + Details?.featuredImage}
                                    alt="CategoryImage"
                                    width={80}
                                    height={80}
                                  />
                                  <br />
                                  <small>Image</small>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Category Name</small>
                                  <h6>{Details?.Category?.categoryName}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small> Sub Category Name</small>
                                  <h6>
                                    {Details?.SubCategory?.subCategoryName}
                                  </h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Sub Sub Category</small>
                                  <h6>
                                    {
                                      Details?.SubSubCategory
                                        ?.subSubCategoryName
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>is Active</small>
                                  <h6>
                                    {Details.isActive === true
                                      ? "True"
                                      : "Flase"}
                                  </h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>brand Name</small>
                                  <h6>{Details.brandName}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>sku</small>
                                  <h6>{Details.sku}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Current Stock</small>
                                  <h6>{Details.currentStock}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Fabric</small>
                                  <h6>{Details.fabric}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>productName</small>
                                  <h6>{Details.productName}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Selected Quantity</small>
                                  <h6>{Details.selectedQuantity}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>tags</small>
                                  <h6>{Details.tags}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Discount Percentage</small>
                                  <h6>{Details.discountPercentage}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Base Price</small>
                                  <h6>{Details.basePrice}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Total Price</small>
                                  <h6>{Details.totalPrice}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Final Amount</small>
                                  <h6>{Details.finalAmount}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Designer Name</small>
                                  <h6>{Details.designerName}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>size</small>
                                  <h6>{Details?.selectedSize}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>colour</small>
                                  <div
                                    className="ms-2"
                                    style={{
                                      display: "flex",
                                      width: "26px",
                                      height: "26px",
                                      borderRadius: "40px",
                                      border: "1px solid black",
                                      backgroundColor: Details.selectedColor,
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Average Rating</small>
                                  <h6>{Details.averageRating}</h6>
                                </div>
                              </div>

                              <div className="col-md-2 mt-2">
                                <div class="vx-col sm:w-1/6 w-full mx-0">
                                  <small>productType</small>
                                  <h6>{Details.type}</h6>
                                  <h6>
                                    {Details?.productType == 1
                                      ? "Hot Deals"
                                      : null}
                                  </h6>

                                  <h6>
                                    {Details?.productType == 0
                                      ? "New Product"
                                      : null}
                                  </h6>
                                  <h6>
                                    {Details?.productType == 3
                                      ? "UP  Coming"
                                      : null}
                                  </h6>
                                  <h6>
                                    {Details?.productType == 2
                                      ? "Best Seller"
                                      : null}
                                  </h6>
                                </div>
                              </div>
                              <div className="col-md-2 mt-2">
                                <div className="vx-col sm:w-1/6 w-full mx-0">
                                  <small>Created At</small>
                                  <h6>
                                    {moment(Details.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default OrderDetails;
