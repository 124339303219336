import React from "react";
// import "../../notFound.css"; // Import the CSS file for styling

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <p>Page Not Found</p>
      </div>
    </div>
  );
}

export default NotFound;