import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";

function AddCoupon() {
  let navigate = useNavigate();
  const [CouponCode, setCouponCode] = useState("");

  const [DISCOUNT, setDISCOUNT] = useState("");
  const [DESCRIPTION, setDESCRIPTION] = useState("");
  const [CouponCodeErr, setCouponCodeErr] = useState("");
  const [DISCOUNTErr, setDISCOUNTErr] = useState("");
  const [DESCRIPTIONErr, setDESCRIPTIONErr] = useState("");
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const OnSubmithandle = async () => {
    if (CouponCode.length == 0 || CouponCode.length < 10) {
      setCouponCodeErr("Please enter a coupon code");
    }
    if (DISCOUNT.length == 0) {
      setDISCOUNTErr("Please enter a DISCOUNT ");
    }
    if (DESCRIPTION.length == 0) {
      setDESCRIPTIONErr("Please enter a DESCRIPTION");
    }
    if (CouponCodeErr == "" && DISCOUNTErr == "" && DESCRIPTIONErr == "") {
      setButtonDisabled(true);
      const data = {
        couponCode: CouponCode,
        description: DESCRIPTION,
        discount: DISCOUNT,
      };
      try {
        const response = await services.discount.Discount_Coupon(data);

        if (response) {
          setToaster({
            type: "success",
            title: "Successful",
            text: "Add Coupon successfully",
            visiblity: "show",
          });
          setTimeout(() => {
            setToaster({
              type: "success",
              title: "success",
              text: "Add Coupon successfully",
              visiblity: "hide",
            });
            navigate("/coupon");
          }, 1000);
        }
      } catch (e) {
        if (e.response.data.message === "coupon already exist") {
          setToaster({
            type: "danger",
            title: "error",
            text: "Coupon already exist",
            visiblity: "show",
          });
          setTimeout(() => {
            setToaster({
              type: "danger",
              title: "error",
              text: "Coupon already exist",
              visiblity: "hide",
            });
            setButtonDisabled(false);
          }, 1000);
        }
      }
    }
  };
  const CouponCodeLength = (value) => {
    value.length < 10
      ? setCouponCodeErr("Please enter a 10 character")
      : setCouponCodeErr("");
  };
  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Add Coupon</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div
                        className="card-body"
                        style={{ margin: "5px", padding: "10px" }}
                      >
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-35px" }}
                        >
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Generate Coupon Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              value={CouponCode}
                              onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^a-zA-Z0-9]/g, "");
                                value = value.slice(0, 10);
                                setCouponCode(value.trim());
                                CouponCodeLength(value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                  setCouponCodeErr("Required");
                                }
                              }}
                            />
                            {CouponCodeErr && (
                              <>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    position: "absolute",
                                  }}
                                >
                                  {CouponCodeErr}
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Discount Percentage{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="defaultFormControlInput"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              onChange={(e) => {
                                let num = e.target.value.trimStart();
                                setDISCOUNTErr("");
                                if (isNaN(num)) return;
                                if (num >= 100) return;
                                setDISCOUNT(e.target.value.trimStart());
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                  setDISCOUNTErr("Required");
                                }
                              }}
                              value={DISCOUNT}
                            />
                            {DISCOUNTErr && (
                              <>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    position: "absolute",
                                  }}
                                >
                                  {DISCOUNTErr}
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Value"
                              aria-describedby="defaultFormControlHelp"
                              onChange={(e) => {
                                setDESCRIPTION(e.target.value.trimStart());
                                setDESCRIPTIONErr("");
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                  setDESCRIPTIONErr("Required");
                                }
                              }}
                              value={DESCRIPTION}
                            />
                            {DESCRIPTIONErr && (
                              <>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    position: "absolute",
                                  }}
                                >
                                  {DESCRIPTIONErr}
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col-md-4 mt-4 ">
                            <button
                              type="submit"
                              disabled={
                                !(CouponCode && DISCOUNT && DESCRIPTION) ||
                                CouponCodeErr ===
                                  "Please enter a 10 character" ||
                                buttonDisabled
                              }
                              onClick={() => {
                                OnSubmithandle();
                              }}
                              className="btn btn-primary ml-md-10"
                            >
                              Add Coupon
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default AddCoupon;
