import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  UPDATE_USERS: (id, user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Users.UPDATE_USERS(id),
          user
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  TOGGLE_USER: (id, user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Users.TOGGLE_USER(id),
          user
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_USERS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Users.GET_USERS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_ALL_USERS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Users.GET_ALL_USERS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ADD_USERS: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Users.ADD_USERS(), data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USERS_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Users.GET_USERS_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_BY_GETALL: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Users.GET_USER_BY_GETALL(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_BY_USERID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Users.GET_USER_BY_USERID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};
