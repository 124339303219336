import React, { useState } from "react";
import services from "../../services";
import { Link, useNavigate } from "react-router-dom";
import Toaster from "../../components/toaster";
// import {logo} from  '../../../public/assets/img/icons/Logo Main.png'
import logo from '../../asset/Logo Main.png'

function Login() {
  let navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [response, setresponse] = useState('')
  const [role, setrole] = useState('admin')
  const [toaster, setToaster] = useState({ visiblity: "hide" });



  const handleGenerateOTP = async (event) => {
    // event.preventDefault();
    // navigate("/users");
    try {
      let payLoad = {
        phoneNumber: phoneNumber,
        countryCode: '+91',
        role:role
      }

      const response = await services.auth.GENERATE_OTP(payLoad);
      if(response.data){
        setresponse(response.data)
      }
      
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "OTP sent successfully",
          visiblity: "show",
        });
       
      }


    } catch (error) {
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message,
        visiblity: "show",
      });
      setTimeout(() => {
        setToaster({
          visiblity: "hide",
        });
      }, 1500);
    }

  };
 
const submitOtp = async() =>{
  try {
    let payload = {
      phoneNumber: phoneNumber,
      otp: otp,
      countryCode:'+91',
      role
  }
    const response = await services.auth.LOGIN_WITH_OTP(payload)
    console.log(response)
    if(response.data){
      localStorage.setItem('role', role);
      // getUserDetails();
      navigate(role == 'admin' ? "/dashboard" : "/vendors");
      setToaster({
        type: "success",
        title: "Login",
        text: 'Login successfully',
        visiblity: "show",
      });
    }

  } catch (error) {
    console.log(error)
    setToaster({
      type: "danger",
      title: "Failed",
      text: 'Invalid OTP',
      visiblity: "show",
    });
  }
}
  return (

    <div className="App">
      <div className="container-xxl">
        <Toaster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />

        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center">
                  <Link to="index.html" className="app-brand-link gap-2">
                    {/* <span className="app-brand-logo demo">

                    </span> */}
                    <span className="app-brand-text demo text-body fw-bolder">

                    </span>
                  </Link>
                </div>

                <p className="mb-4 fs-4">Welcome To Cars53 Admin! <img src={logo} height={38} alt="" width={38} style={{ marginRight: '5px' }} /> </p>
                <p className="mb-4 fs-4">Please sign-in to your account</p>
              
               {response ? <> <div className="mb-1 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">Enter OTP</label>

                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type='password'
                      id="password"
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                      className="form-control"
                      name="password"
                      onKeyDown={(e) => {
                        e.key === "Enter"
                          ? submitOtp()
                          : setOtp(e.target.value);
                       
                      }}
                      placeholder="Enter OTP"
                      aria-describedby="password"
                    />
                   
                  </div>
                </div>
                
                <div className="mb-3 mt-3">
                  <button

                    onClick={submitOtp}
                    className="btn btn-primary d-grid w-100"
                  >
                    Submit OTP
                  </button>
                </div></> :  <>  <div className="mb-3">
                  <label className="form-label">
                    Enter your phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={phoneNumber}
                    placeholder="Enter your phone"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter"
                        ? handleGenerateOTP()
                        : setPhoneNumber(e.target.value);
                    }}
                  />
                 
                </div>
                
                <div className="mb-3">
                  <label className="form-label">
                    Select Role
                  </label>
                 <select className="form-control" value={role} onChange={(e)=> setrole(e.target.value)}>
                  <option value={'admin'}>Admin</option>
                  <option value={'subadmin'}>Sub admin</option>
                 </select>
                 
                </div>
                 <div className="mb-3 mt-3">
                  <button

                    onClick={handleGenerateOTP}
                    className="btn btn-primary d-grid w-100"
                  >
                    Generate OTP
                  </button>
                </div></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;