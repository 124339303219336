import React, { useEffect, useState } from "react";
import services from "../../services";
import { useParams } from "react-router-dom";
import moment from "moment";
const Userdetails = () => {
  const { id } = useParams();
  const [rowData, setRowData] = useState();
  const getOrderListHandler = async () => {
    try {
      const response = await services.Users.GET_USER_BY_USERID(id);
      console.log("Userdetails", response.data.data);
      setRowData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderListHandler();
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>Account Details</h5>
        </div>
        <div className="card-body">
          <form method="post">
            <div className="row">
              <div className="form-group col-md-6">
                <label>
                  First Name
                  <span className="required">*</span>
                </label>
                <input
                  required=""
                  className="form-control square"
                  name="name"
                  disabled="true"
                  type="text"
                  value={rowData?.firstName}
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  Last Name
                  <span className="required">*</span>
                </label>
                <input
                  required=""
                  className="form-control square"
                  disabled="true"
                  name="name"
                  value={rowData?.lastName}
                />
              </div>

              <div className="form-group col-md-12">
                <label>
                  Phone Number
                  <span className="required">*</span>
                </label>
                <input
                  required=""
                  className="form-control square"
                  disabled="true"
                  name="phone"
                  type="number"
                  value={rowData?.phoneNumber}
                />
              </div>
              <div className="form-group col-md-12">
                <label>
                  Email Address
                  <span className="required">*</span>
                </label>
                <input
                  required=""
                  className="form-control square"
                  disabled="true"
                  name="email"
                  type="email"
                  // value={email}
                  value={rowData?.email}
                />
                <div className="form-group col-md-12">
                  <label>
                    Date of Birth
                    <span className="required">*</span>
                  </label>
                  <input
                    // required=""
                    className="form-control square"
                    // name="dob"
                    disabled="true"
                    // type="date"
                    value={moment(rowData?.dob).format("DD-MM-yyyy")}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Userdetails;
