import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import SideBar from "../../components/sideBar";
import Header from "../../components/header";
import services from "../../services";
import Toaster from "../../components/toaster";
function EditOrder() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [toaster, setToaster] = useState({ visiblity: "hide" });
  const [order, setOrder] = useState("");
  const [orderEdit, setOrderEdit] = useState(0);

  //Error handle State
  console.log("order", order);
  const [orderError, setOrderError] = useState("");

  const GET_BY_ID_ORDER = useCallback(async () => {
    const response = await services.order.GET_ORDER_BY_ID(id);
    console.log("================", response.data.data.OrderDetails[0].id);
    const type = response?.data?.data?.OrderDetail?.type;
    setOrderEdit(response?.data?.data?.OrderDetails[0]?.id);

    setOrder(type);
  }, [id]);

  useEffect(() => {
    GET_BY_ID_ORDER();
  }, [GET_BY_ID_ORDER]);

  const OnSubmithandle = async () => {
    let isload = true;
    if (orderError) {
      isload = false;
    }

    const data = {
      type: order,
    };

    if (isload) {
      try {
        const response = await services.order.UPDATE_ORDER(orderEdit, data);
        if (response) {
          setToaster({
            type: "success",
            title: "Successful ",
            text: " Edit order Successfully",
            visiblity: "show",
          });
          setTimeout(() => {
            navigate("/orders");
          }, 500);
        }
      } catch (error) {
        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error.response.data.message,
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            visiblity: "hide",
          });
        }, 1500);
      }
    } else {
    }
    //navigate student list
  };

  return (
    <div className="App">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBar />

          <div className="layout-page">
            <Header />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="d-flex py-3 align-items-center justify-content-between">
                  <h4 className="fw-bold ">Edit Order</h4>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="card mb-4" style={{ margin: "-12px" }}>
                      <div
                        className="card-body"
                        style={{ margin: "5px", padding: "10px" }}
                      >
                        <div
                          className="row align-items-end"
                          style={{ marginTop: "-40px" }}
                        >
                          <div className="col-md-4 mt-4">
                            <label
                              htmlFor="defaultFormControlInput"
                              className="form-label"
                            >
                              Order Type
                            </label>

                            <select
                              className="form-select"
                              id="schoolyearSelect"
                              value={order}
                              onChange={(e) => {
                                setOrder(e.target.value.trim()); // Trim whitespace before setting
                                if (e.target.value.trim()) {
                                  setOrder(e.target.value);
                                }
                                if (e.target.value.length === 0) {
                                  setOrderError("Required");
                                } else {
                                  setOrderError("");
                                }
                              }}
                            >
                              <option value="">Select Order Type</option>
                              <option value="Delivered">Delivered</option>
                              <option value="Refund">Refund</option>
                              <option value="Cancel">Cancel</option>
                            </select>
                            {orderError && (
                              <>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    position: "absolute",
                                  }}
                                >
                                  {orderError}
                                </span>
                              </>
                            )}
                          </div>

                          <div className="col-md-4 mt-4 ">
                            <button
                              type="submit"
                              disabled={!order}
                              onClick={() => {
                                OnSubmithandle();
                              }}
                              className="btn btn-primary ml-md-10"
                            >
                              Save Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                  <div className="mb-2 mb-md-0">© Cars53</div>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
}

export default EditOrder;
